<template>
  <vx-card>
    <div class="vx-col sm:w-1/1 w-full mb-base">
      <vs-tabs>
        <vs-tab label="ST Level Report">
          <div class="tab-text">
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Principal</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedPrincipals"
                  :options="optionPrincipals"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customIdLabel"
                  track-by="id"
                  @search-change="findPrincipals"
                  :searchable="true"
                  :loading="isLoadingPrincipals"
                  :internal-search="false"
                  :close-on-select="false"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Material</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedMaterials"
                  :options="optionMaterials"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                  @search-change="findMaterials"
                  :searchable="true"
                  :loading="isLoadingMaterials"
                  :internal-search="false"
                  :close-on-select="false"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedWarehouses"
                  :options="optionWarehouses"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customIdLabel"
                  track-by="id"
                  @search-change="findWarehouses"
                  :searchable="true"
                  :loading="isLoadingWarehouses"
                  :internal-search="false"
                  :close-on-select="false"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-button color="success" @click="handleDrawTable()"
                  >Get Data</vs-button
                >
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <data-table
                  :baseUrl="this.baseUrl"
                  :territoryIDs="this.territoryIDs"
                  :territoryNames="this.territoryNames"
                  :skuCodes="this.skuCodes"
                  :principalIDs="this.principalIDs"
                  :principalNames="this.principalNames"
                  :draw="this.draw"
                ></data-table>
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <data-table-export></data-table-export>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import DataTable from './DataTable.vue';
import DataTableExport from './DataTableExport.vue';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { debounce } from 'lodash';

export default {
  components: {
    DataTable,
    DataTableExport,
    DateRangePicker,
    Datepicker,
  },
  data() {
    return {
      baseUrl: '/api/report/v1/territories',
      selectedTerritories: [],
      optionTerritories: [],
      territoryIDs: [],
      territoryCodes: [],
      territoryNames: [],
      selectedSKUCodes: [],
      optionSKUCodes: [],
      isLoadingSKUCodes: false,
      selectedPrincipals: [],
      optionPrincipals: [],
      isLoadingPrincipals: false,
      selectedMaterials: [],
      optionMaterials: [],
      isLoadingMaterials: false,
      selectedWarehouses: [],
      optionWarehouses: [],
      isLoadingWarehouses: false,
      skuCodesQuery: '',
      principalQuery: '',
      materialQuery: '',
      warehouseQuery: '',
      principalIDs: [],
      principalNames: [],
      skuCodes: [],
      draw: 0,
    };
  },
  methods: {
    customLabel(val) {
      if (val) {
        return val.code === '-' ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
    customIdLabel(val) {
      if (val) {
        return val.id === '-' ? `${val.name}` : `(${val.id}) ${val.name}`;
      }
    },
    handleClose() {
      this.detail = false;
    },
    
    handleOpen() {
      this.detail = true;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format('dddd, MMMM Do YYYY');
      }
      return a;
    },
    getTerritories() {
      this.$http.get('/api/report/v1/master/territory').then((resp) => {
        this.optionTerritories = resp.data.records;
      });
    },
    getSKUCodes() {
      this.isLoadingSKUCodes = true;
      const queryString = '?search=' + this.skuCodesQuery;
      this.$http
        .get(
          '/api/report/v1/master/sku-code' +
            (this.skuCodesQuery && this.skuCodesQuery !== '' ? queryString : '')
        )
        .then((resp) => {
          this.optionSKUCodes = resp.data.records;
          this.isLoadingSKUCodes = false;
        });
    },
    getPrincipals() {
      this.isLoadingPrincipals = true;
      const queryString = '?search=' + this.principalQuery;
      this.$http
        .get(
          '/api/report/v1/master/principal' +
            (this.principalQuery && this.principalQuery !== ''
              ? queryString
              : '')
        )
        .then((resp) => {
          this.optionPrincipals = resp.data.records;
          this.isLoadingPrincipals = false;
        });
    },
    getMaterials() {
      this.isLoadingMaterials = true;
      const queryString = '?search=' + this.materialQuery;
      this.$http
        .get(
          '/api/report/v1/master/material' +
          (this.materialQuery && this.materialQuery !== '' ? queryString : '')
        )
        .then((resp) => {
          this.optionMaterials = resp.data.records;
        }).finally(() => {
          this.isLoadingMaterials = false;
        });
    },
    getWarehouses() {
      this.isLoadingWarehouses = true;
      const queryString = '?search=' + this.warehouseQuery;
      this.$http
        .get(
          '/api/report/v1/master/warehouse' +
            (this.warehouseQuery && this.warehouseQuery !== '' ? queryString : '')
        )
        .then((resp) => {
          this.optionWarehouses = resp.data.records;
          this.isLoadingWarehouses = false;
        });
    },
    handleDrawTable() {
      this.draw++;
    },
    findSKUCodes: debounce(function (query) {
      this.skuCodesQuery = query;
    }, 500),
    findPrincipals: debounce(function (query) {
      this.principalQuery = query;
    }, 500),
    findMaterials: debounce(function (query) {
      this.materialQuery = query;
    }, 500),
    findWarehouses: debounce(function (query) {
      this.warehouseQuery = query;
    }, 500),
  },
  mounted() {
    this.getTerritories();
    this.getSKUCodes();
    this.getPrincipals();
    this.getMaterials();
    this.getWarehouses();
    this.draw++;
  },
  watch: {
    selectedTerritories(val) {
      let territoryIDs = [];
      let territoryCodes = [];
      let territoryNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          territoryIDs.push(e.id);
          territoryCodes.push(e.code);
          territoryNames.push(e.name);
        }
      });

      this.territoryIDs = territoryIDs;
      this.territoryCodes = territoryCodes;
      this.territoryNames = territoryNames;
    },
    selectedPrincipals(val) {
      let principalIDs = [];
      let principalCodes = [];
      let principalNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          principalIDs.push(e.id);
          principalCodes.push(e.Code);
          principalNames.push(e.Name);
        }
      });
      this.principalIDs = principalIDs;
      this.principalNames = principalNames;
    },
    principalQuery(val) {
      this.getPrincipals();
    },
    selectedSKUCodes(val) {
      let skuCodes = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          skuCodes.push(e.id);
        }
      });
      this.skuCodes = skuCodes;
    },
    skuCodesQuery() {
      this.getSKUCodes();
    },
    selectedMaterials(val) {
      let materialIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          materialIDs.push(e.id);
        }
      });
      this.materialIDs = materialIDs;
    },
    materialQuery() {
      this.getMaterials();
    },
    selectedWarehouses(val) {
      let warehouseIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          warehouseIDs.push(e.id);
        }
      });
      this.warehouseIDs = warehouseIDs;
    },
    warehouseQuery() {
      this.getWarehouses();
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
